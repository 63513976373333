<template>
  <credentials-editor
    :credentials="credentials"
    :projectId="project.objectID"
    v-on:save-and-exit="create"
    v-if="project.objectID"
  ></credentials-editor>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import CredentialsEditor from "@/components/CredentialsEditor";

export default {
  name: "CredentialsNew",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    CredentialsEditor,
  },
  data() {
    return {
      project: {},
      credentials: {
        name: "",
        type: "basic",
        meta: {},
      },
      root: ["folders", "credentials", ""],
      endpoints: {},
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    userUid() {
      return this.userProfile.uid;
    },
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        this.bindObject("selected", uid, "project");
      },
    },
    project: {
      immediate: true,
      handler(project) {
        if (project.objectID) {
          this.root[2] = project.objectID;
          console.log("Project", project.objectID, this.$route.query.path);
          if (this.$route.query.path !== undefined) {
            this.setRootFromPath(this.$route.query.path);
          }
        }
      },
    },
  },
  methods: {
    create() {
      let credentials = { ...this.credentials };
      credentials.path = this.root.join("/");

      this.createObjectKey("credentials/" + this.project.objectID)
        .then((key) => {
          Promise.all([
            this.updateObject(
              "credentials/" + this.project.objectID,
              key,
              credentials
            ),
            this.updateObject(this.root.join("/"), key, credentials),
          ])
            .then(() => {
              this.notyf.success("Credentials created successfully");
              this.navigate("credentials-edit", { id: key });
            })
            .catch((error) => {
              console.log(error);
              this.notyf.error(
                "An error occurred while saving the credentials"
              );
            });
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error("An error occurred while saving the credentials");
        });
    },
    addEndpoint(endpoint, key) {
      this.endpoints[endpoint] = key;
      const endpoints = { ...this.endpoints };
      return this.updateObject("endpoints", this.project.objectID, endpoints);
    },
    async setRootFromPath(routePath) {
      console.log("Route Path: " + routePath);
      const segments = routePath.split("/");
      let root = ["folders", "credentials", this.project.objectID];
      for (const segment of segments) {
        const snapshot = await this.getObjectSnapshot(
          root.join("/"),
          null,
          true
        );
        const folder = snapshot.val();
        console.log("Folder", folder);
        if (folder && "folders" in folder) {
          Object.keys(folder.folders).forEach((subkey) => {
            const subfolder = folder.folders[subkey];
            if (subfolder.slug === segment.toLowerCase()) {
              root.push("folders", subkey);
            }
          });
        }
      }
      this.root = [...root, "items"];
    },
  },
};
</script>

<style lang="css" scoped>
.field-card {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 10%) !important;
}

.no-body-margin {
  margin-left: -20px;
  margin-right: -20px;
  width: 107.5%;
}

.card-title-button {
  margin-top: -5px;
}

.list-header-button {
  width: 107%;
  margin-left: -16px !important;
  margin-right: -16px !important;
  text-align: left;
}

.list-method {
  padding-top: 0;
  padding-bottom: 0;
}

.prism-editor {
  height: 500px;
  border: 1px solid #545968;
  border-radius: 0.25rem;
  background: #363d4f;
}
</style>
