<template>
  <hierarchical-index
    :dbref="dbref"
    :title="title"
    :new-button-label="newButtonLabel"
    :fields="fields"
    :search-fields="searchFields"
  >
    <template #cell(type)="data">
      <span v-if="!data.item.isFolder">
        {{ data.item.type }}
      </span>
    </template>
  </hierarchical-index>
</template>

<script>
import HierarchicalIndex from "@/components/HierarchicalIndex";

export default {
  name: "Credentials",
  inject: ["notyf"],
  components: {
    HierarchicalIndex,
  },
  data() {
    return {
      dbref: "credentials",
      title: "Credentials",
      newButtonLabel: "New Credentials",
      fields: [
        {
          key: "select",
          label: "",
          sortable: false,
        },
        {
          key: "icon",
          label: "",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      searchFields: ["name", "type"],
    };
  },
};
</script>
