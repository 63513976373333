<template>
  <form-wizard
    id="wizard"
    class="wizard wizard-primary sw sw-theme-default sw-justified"
    color="#3f80ea"
    @on-complete="save(true)"
    @on-save="save(false)"
    :hideSaveButton="isNew"
    :finishButtonText="isNew ? 'Create' : 'Save & Exit'"
  >
    <tab-content title="Details" description="Specify configuration details">
      <form>
        <b-form-group label="Name">
          <b-form-input
            type="text"
            placeholder="e.g. My Credentials"
            v-model="credentials.name"
            required
            trim
          />
        </b-form-group>
        <b-form-group label="Type">
          <b-form-radio-group
            v-model="credentials.type"
            :options="[
              { value: 'key', text: 'API Key' },
              { value: 'basic', text: 'Basic Auth' },
              { value: 'bearer', text: 'Bearer Token' },
            ]"
          ></b-form-radio-group>
        </b-form-group>
        <b-form-group label="API Header" v-if="credentials.type === 'key'">
          <b-form-input
            type="text"
            placeholder="e.g. X-API-KEY"
            v-model="credentials.api_header"
            required
            trim
          />
        </b-form-group>
        <b-form-group label="API Key" v-if="credentials.type === 'key'">
          <b-form-input
            type="text"
            placeholder="e.g. hunter2"
            v-model="credentials.api_key"
            required
            trim
          />
        </b-form-group>
        <b-form-group label="Username" v-if="credentials.type === 'basic'">
          <b-form-input
            type="text"
            placeholder="e.g. my-user@domain.com"
            v-model="credentials.username"
            required
            trim
          />
        </b-form-group>
        <b-form-group label="Password" v-if="credentials.type === 'basic'">
          <b-form-input
            type="password"
            placeholder="e.g. hunter2"
            v-model="credentials.password"
            required
            trim
          />
        </b-form-group>
        <b-form-group label="Bearer Token" v-if="credentials.type === 'bearer'">
          <b-form-input
            type="text"
            placeholder="e.g. eyJhbGciOiJ..."
            v-model="credentials.token"
            required
            trim
          />
        </b-form-group>
        <div class="mt-4 mb-2 d-flex justify-content-between">
          <h5 class="text-muted">User metadata</h5>
          <div>
            <b-button variant="primary" size="sm" @click="addMeta"
              ><i class="fas fa-plus"></i> Add Metadata</b-button
            >
          </div>
        </div>
        <b-table class="mb-3" striped :items="metadata" :fields="fields">
          <template #cell(name)="data">
            <b-form-input
              v-model="data.item.name"
              placeholder="e.g. firstName"
            ></b-form-input>
          </template>
          <template #cell(value)="data">
            <b-form-input
              v-model="data.item.value"
              placeholder="e.g. John"
            ></b-form-input>
          </template>
          <template #cell(actions)="data">
            <b-button variant="danger" size="sm" @click="deleteMeta(data.index)"
              ><i class="far fa-trash-alt"></i
            ></b-button>
          </template>
        </b-table>
      </form>
    </tab-content>
  </form-wizard>
</template>

<script>
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import FormWizard from "@/components/CustomFormWizard";
import TabContent from "@/components/CustomTabContent";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  name: "CredentialsEditor",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    FormWizard,
    TabContent,
  },
  props: {
    credentials: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      bind: [],
      fieldIndex: null,
      field: {},
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "value",
          label: "Value",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          sortable: true,
        },
      ],
      metadata: [],
    };
  },
  computed: {
    isNew() {
      return !("path" in this.credentials);
    },
  },
  watch: {
    credentials: {
      immediate: true,
      handler(credentials) {
        if ("meta" in credentials) {
          Object.keys(credentials.meta).forEach((name) => {
            this.metadata.push({
              name: name,
              value: credentials.meta[name],
            });
          });
          this.$forceUpdate();
        }
      },
    },
  },
  methods: {
    save(exit = false) {
      this.credentials.meta = {};
      this.metadata.forEach((meta) => {
        this.credentials.meta[meta.name] = meta.value;
      });
      if (exit) {
        this.$emit("save-and-exit", this.credentials);
      } else {
        this.$emit("save", this.credentials);
      }
    },
    addMeta() {
      this.metadata.push({});
      this.$forceUpdate();
    },
    deleteMeta(index) {
      this.metadata.splice(index, 1);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="css" scoped>
.field-card {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 10%) !important;
}

.no-body-margin {
  margin-left: -20px;
  margin-right: -20px;
  width: 107.5%;
}

.card-title-button {
  margin-top: -5px;
}

.list-header-button {
  width: 107%;
  margin-left: -16px !important;
  margin-right: -16px !important;
  text-align: left;
}

.list-method {
  padding-top: 0;
  padding-bottom: 0;
}

.prism-editor {
  height: 500px;
  border: 1px solid #545968;
  border-radius: 0.25rem;
  background: #363d4f;
}
</style>
